import { Button, Col, Divider, Form, Input, message, Radio, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEditAdminPwd } from "../../services/admin-service";
import { useDocumentTitle } from "../../utils"
import { getToken } from "../../utils/auth-provider";

export const AdminUpdatePage = () => {
    //标题
    useDocumentTitle('修改密码', true)
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //新建
    const useMutateEditAdminPwd = useEditAdminPwd;
    const {mutateAsync, error } =  useMutateEditAdminPwd();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then((res) => {
            if(res.status == 1) {
                message.info(res.msg);
            } else {
                message.info("修改成功");
            }
            setSubmitLoading(false);
        });
    };
    return (
        <div>
           
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}>
                <Form.Item
                    label={"旧密码"}
                    name={"oldPassword"}
                    rules={[{ required: true, message: "旧密码必填" }]}
                >
                    <Input.Password placeholder={"请输入旧密码"} />
                </Form.Item>

                <Form.Item
                    label={"新密码"}
                    name={"newPassword"}
                    rules={[{ required: true, message: "新密码必填" }]}
                >
                    <Input.Password placeholder={"请输入新密码"} />
                </Form.Item>

                <Form.Item
                    label={"确认密码"}
                    name={"againPassword"}
                    rules={[{ required: true, message: "确认密码必填" }]}
                >
                    <Input.Password placeholder={"请输入确认密码"} />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>  
            </Form>
        </div>
    )
}