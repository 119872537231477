import { Button, Col, Menu, message, Modal, Row, Space, Table } from "antd";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useReportTypeList,
  useDeleteReportType,
} from "../../services/report-type-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useReportTypeSearchParams } from "./util";

export const ReportTypeListPage = () => {
  useDocumentTitle("报告类型列表", true);
  const [param, setParam] = useReportTypeSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useReportTypeList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteReportType = useDeleteReportType;
  const { mutateAsync } = useMutateDeleteReportType();
  const confirmDeleteReportType = (reportTypeId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ reportTypeId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/report/report-manages/ReportType/add"}>
            <Button type="primary">新建类型</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Search
            placeholder="可搜索类型名称"
            style={{ width: 200 }}
            value={param.reportTypeName}
            onChange={(evt) =>
              setParam({
                ...param,
                reportTypeName: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                reportTypeName: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.reportTypeId}
        pagination={paginationProps}
        columns={[
          {
            title: "类型ID",
            dataIndex: "reportTypeId",
            key: "reportTypeId",
            width: 100,
          },
          {
            title: "类型名称",
            dataIndex: "reportTypeName",
            key: "reportTypeName",
          },
          {
            title: "操作",
            key: "action",
            render: (value, reportType) => (
              <Space size="middle">
                <Link
                  to={
                    "/report/report-manages/reportType/update/" +
                    reportType.reportTypeId
                  }
                >
                  编辑
                </Link>
                <a
                  onClick={() =>
                    confirmDeleteReportType(reportType.reportTypeId)
                  }
                >
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
