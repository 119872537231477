import { useMutation, useQuery, useQueryClient } from "react-query";
import { Member } from "../interfaces/member";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMemberList = (param?: Partial<Member>) => {
    const client = useHttp();
    const result = useQuery<any>(['memberPage', param], () => client('member', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

