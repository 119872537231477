import { Typography, Col, Row, Space } from 'antd'
import './index.css';
import { useState } from 'react';
const { Text } = Typography;
export const DashboardPage = () => {
  const [nowTime, setNowTime] = useState(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString());
  return (
    <div style={{ background: '#fff' }}>
      概况
    </div>
  )
}
