import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
  Upload,
  Menu,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useReport } from "../../services/report-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const ReportPreviewPage = () => {
  //标题
  useDocumentTitle("报告预览", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const reportId = pathname.match(/report\/report-manage\/report\/preview\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingReport, isLoading } = useReport(Number(reportId));
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    form.setFieldsValue(editingReport?.data);
    const pdfUrl = imgUrl + editingReport?.data?.reportFile;
    setImageUrl(pdfUrl);
  }, [editingReport, form]);

  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["report"]}
      >
        <Menu.Item key="report">
          <Link to={"/report/report-manage/report/list"}>报告单列表</Link>
        </Menu.Item>
      </Menu>
      <Spin spinning={isLoading}>
        <Form form={form} {...layout}>
          <iframe
            className="iframe"
            src={imageUrl}
            width="100%"
            height={800}
          ></iframe>
        </Form>
      </Spin>
    </div>
  );
};
