import { useMutation, useQuery, useQueryClient } from "react-query";
import { Report } from "../interfaces/report";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useReportList = (param?: Partial<Report>) => {
    const client = useHttp();
    const result = useQuery<any>(['reportList', param], () => client('report', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditReport = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Report>) => client(`report`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportList')
        }
    )
}

export const useAddReport = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Report>) => client(`report`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportList')
        }
    )
}

export const useDeleteReport = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ reportId }: { reportId: number}) =>
            client(`report/${reportId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportList')
        }
    );
};

export const useReport = (reportId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['report', {reportId}],
        () => client(`report/${reportId}`),
        {
            enabled: Boolean(reportId)
        }
    )
}


// 同意
export const useAgreeState = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ reportId }: { reportId: number}) =>
            client(`report/finish/${reportId}`, {
            method: "Put",
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportList')
        }
    );
};


export const useConfirmSend = () => {
    const client = useHttp();
     const queryClient = useQueryClient()
    return useMutation((params: Partial<Report>) =>
      client(`report/send`, {
        data: params,
        method: 'PUT',
      }), { onSuccess: () => queryClient.invalidateQueries('reportList')}
    );
  };

