import { Button, Col, message, Modal, Row, Space, Table, Image, Breadcrumb } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMailboxList, useDeleteMailbox } from "../../services/mailbox-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMailboxSearchParams } from "./util"
 
export const MailboxListPage = () => {
    useDocumentTitle('邮箱列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL
    const [param, setParam] = useMailboxSearchParams()
    const {isLoading, error, totalElements, data: list} = useMailboxList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteMailbox = useDeleteMailbox;
    const { mutateAsync } = useMutateDeleteMailbox();
    const confirmDeleteMailbox = (mailboxId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ mailboxId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div> 
            <Breadcrumb style={{ marginBottom: 24 }}>
                <Breadcrumb.Item>邮箱列表</Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{marginBottom: 24}}> 
                <Col span={12}>
                    <Link to={"/setting/mailbox/add"}>
                        <Button type="primary">新建邮箱</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search
                        placeholder="可搜索邮箱标题"
                        style={{ width: 200 }}
                        value={param.mailboxTitle}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            mailboxTitle: evt.target.value,
                            pageNum: null
                        })}
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            mailboxTitle: value,
                            pageNum: null
                        })}
                    />
                </Col>
            </Row>
            <Table
                loading = { isLoading }
                rowKey={ columns => columns.mailboxId }
                pagination = { paginationProps }
                columns = {[
                {
                    title: '邮箱ID',
                    dataIndex: 'mailboxId',
                    key: 'mailboxId',
                    width: 100,
                },
                {
                    title: '邮箱号',
                    dataIndex: 'mailboxNumber',
                    key: 'mailboxNumber',
                },
                {
                    title: '备注',
                    dataIndex: 'mailboxRemark',
                    key: 'mailboxRemark',
                },
                {
                    title: '绑定报告',
                    dataIndex: 'reportTypeName',
                    key: 'reportTypeName',
                },
                {
                    title: '是否默认',
                    dataIndex: 'mailboxDefault',
                    key: 'mailboxDefault',
                    render: (value, mailbox) => (
                        <span>{mailbox.mailboxDefault ? '是' : '否'}</span>
                    ),
                },
                {
                    title: '是否使用',
                    dataIndex: 'mailboxUse',
                    key: 'mailboxUse',
                    render: (value, mailbox) => (
                        <span>{mailbox.mailboxUse ? '是' : '否'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, mailbox) => (
                        <Space size="middle">
                            <Link to={"/setting/mailbox/update/" + mailbox.mailboxId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteMailbox(mailbox.mailboxId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}
