import styled from "@emotion/styled";
import { Button, Card, Form, Input } from "antd";
import { useDocumentTitle } from "../../utils";
import logo from "../../assets/logo.png";
import bg from "../../assets/bg.svg";
import { useAuth } from "../../context/auth-context";
import { useAsync } from "../../utils/use-async";
import { ErrorBox } from "../../components/lib";
export const Login = ({ onError }: { onError: (error: Error) => void }) => {
  useDocumentTitle("厦门飞朔生物技术有限公司");
  const { login } = useAuth();
  const { run } = useAsync(undefined, { throwOnError: false });

  const handleSubmit = async (values: {
    adminAccountName: string;
    adminAccountPassword: string;
  }) => {
    try {
      await run(login(values));
    } catch (e: any) {
      onError(new Error("登陆失败"));
    }
  };

  return (
    <Container>
      <Background />
      <Main>
        <Header />
        <ShadowCard>
          <Title></Title>
          <ErrorBox error={onError} />
          <Form onFinish={handleSubmit}>
            <Form.Item colon={false} label="登录账号" name="adminAccountName">
              <Input />
            </Form.Item>

            <Form.Item
              colon={false}
              label="登录密码"
              name="adminAccountPassword"
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <LongButton htmlType={"submit"} type={"primary"}>
                登录
              </LongButton>
            </Form.Item>
          </Form>
        </ShadowCard>
      </Main>
      <Footer>
      <div>厦门飞朔生物技术有限公司</div>
      <div>© 闽ICP备20013340号-1</div>
  </Footer>
     
    </Container>
  );
};

const Footer = styled.div`
width: 100%;
text-align: center;
position: fixed;
bottom: 0px;
color: rgba(0, 0, 0, 0.45);
font-size: 12px;
display: flex;
flex-direction: column;
justify-content: center; /* 可选，用于垂直居中内容 */
padding: 10px 0; /* 上下内边距，确保文本不会紧贴底部 */
`;

export const LongButton = styled(Button)`
  width: 100%;
  height: 3rem;
  font-size: 18px;
`;

const Title = styled.h2`
  margin-bottom: 2.4rem;
  color: #1f2026;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom, right bottom;
  /* background-size: cover; */
  background-image: url(${bg});
  background-color: #f0f2f5;
`;

const Header = styled.header`
  background: url(${logo}) no-repeat center;
  margin-top: -10rem;
  height: 10rem;
  background-size: 12rem;
  width: 50rem;
  z-index: 100;
`;

const ShadowCard = styled(Card)`
  width: 25rem;
  padding: 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;
const Main = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
`;
