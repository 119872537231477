import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddAdvPage } from "../../services/adv-page-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const AdvPageAddPage = () => {
  //标题
  useDocumentTitle("新建轮播", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备跳转链接
  const [propertyShow, setPropertyShow] = useState(false);
  const [propertyName, setPropertyName] = useState("参数");
  const { data: pageUrlList } = usePageUrlList();
  const options = pageUrlList?.map((item: any, value: any) => (
    <Option value={item.pageUrlId} key={item.pageUrlId}>
      {item.pageUrlName}
    </Option>
  ));
  //option选中与非选中
  const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
  function optionHandleChange(pageUrlId: number) {
    mutateAsyncPageUrl({ pageUrlId }).then((data) => {
      const pageUrlFlag = data.data.pageUrlFlag;
      const pageUrlPropertyName = data.data.pageUrlPropertyName;
      setPropertyShow(pageUrlFlag);
      setPropertyName(pageUrlPropertyName);
    });
  }
  //表单
  const [form] = useForm();
  //图片上传
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        advPagePic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //新建
  const useMutateAddAdvPage = useAddAdvPage;
  const { mutateAsync, error } = useMutateAddAdvPage();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/shop/advPage/list";
    });
  };
  return (
    <div>
      <Breadcrumb style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to={"/shop/advPage/list"}>轮播列表</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>新建轮播</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col span={12}>
          <Link to={"/shop/advPage/list"}>
            <Button type="primary">轮播列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          advPageSort: 0,
          advPageShow: true,
        }}
      >
        <Form.Item
          label={"轮播标题"}
          name={"advPageTitle"}
          rules={[{ required: true, message: "轮播标题必填" }]}
        >
          <Input placeholder={"请输入轮播标题"} />
        </Form.Item>

        <Form.Item
          name={"uploadPic"}
          label={"轮播图片"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "轮播图片必传" }]}
          style={{ textAlign: "left" }}
        >
          <Upload
            name="imageFile"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={apiUrl + "/upload/img"}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{ Authorization: "Bearer " + token }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          hidden
          label={"轮播图片"}
          name={"advPagePic"}
          rules={[{ required: true, message: "轮播图片必传" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={"跳转链接"}
          name={"pageUrlId"}
          rules={[{ required: true, message: "跳转链接必选" }]}
        >
          <Select
            showSearch
            placeholder="请选择跳转链接"
            optionFilterProp="children"
            style={{ textAlign: "left" }}
            onChange={optionHandleChange}
          >
            {options}
          </Select>
        </Form.Item>

        {propertyShow ? (
          <Form.Item
            label={propertyName}
            name={"pageUrlPropertyValue"}
            rules={[{ required: true, message: "链接参数必传" }]}
          >
            <Input placeholder={"请输入链接参数"} />
          </Form.Item>
        ) : (
          ""
        )}

        <Form.Item
          label={"轮播排序"}
          name={"advPageSort"}
          rules={[{ required: true, message: "轮播排序必填" }]}
        >
          <Input placeholder={"请输入轮播排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"advPageShow"}
          rules={[{ required: true, message: "轮播显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              显示
            </Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
