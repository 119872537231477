import { useMutation, useQuery, useQueryClient } from "react-query";
import { ReportType } from "../interfaces/report-type";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useReportTypeList = (param?: Partial<ReportType>) => {
    const client = useHttp();
    const result = useQuery<any>(['reportTypeList', param], () => client('reportType', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditReportType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ReportType>) => client(`reportType`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportTypeList')
        }
    )
}

export const useAddReportType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ReportType>) => client(`reportType`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportTypeList')
        }
    )
}

export const useDeleteReportType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ reportTypeId }: { reportTypeId: number}) =>
            client(`reportType/${reportTypeId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportTypeList')
        }
    );
};

export const useReportType = (reportTypeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['reportType', {reportTypeId}],
        () => client(`reportType/${reportTypeId}`),
        {
            enabled: Boolean(reportTypeId)
        }
    )
}


/**查询全部 */
export const useReportTypeListAll = (param?: Partial<ReportType>) => {
    const client = useHttp();
    const result = useQuery<any>(["reportTypeListAll", param], () =>
        client("reportType/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};


