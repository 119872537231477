import { useMutation, useQuery, useQueryClient } from "react-query";
import { Mailbox } from "../interfaces/mailbox";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMailboxList = (param?: Partial<Mailbox>) => {
    const client = useHttp();
    const result = useQuery<any>(['mailboxList', param], () => client('mailbox', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditMailbox = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Mailbox>) => client(`mailbox`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('mailboxList')
        }
    )
}

export const useAddMailbox = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Mailbox>) => client(`mailbox`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('mailboxList')
        }
    )
}

export const useDeleteMailbox = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ mailboxId }: { mailboxId: number}) =>
            client(`mailbox/${mailboxId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('mailboxList')
        }
    );
};

export const useMailbox = (mailboxId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['mailbox', {mailboxId}],
        () => client(`mailbox/${mailboxId}`),
        {
            enabled: Boolean(mailboxId)
        }
    )
}


/**查询全部 */
export const useMailboxListAll = (param?: Partial<Mailbox>) => {
    const client = useHttp();
    const result = useQuery<any>(["mailboxListAll", param], () =>
        client("mailbox/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};

// 默认的邮箱
export const useMailboxDefault = () => {
    const client = useHttp()
    return useQuery<any>(
        ['mailbox'],
        () => client(`mailbox/default`,{method:'GET'})
    )
}

export const useMailboxDefaults = () => {
    const client = useHttp();
     const queryClient = useQueryClient()
    return useMutation((params: Partial<Mailbox>) =>
      client(`mailbox/default`, {
        data: params,
        method: 'Get',
      }), { onSuccess: () => queryClient.invalidateQueries('mailbox')}
    );
  };
