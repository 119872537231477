import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useAboutUs,
  useEditAboutUs,
} from "../../services/about-us-service";
import { useDocumentTitle } from "../../utils";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import BraftEditor from "braft-editor";
import { getToken } from "../../utils/auth-provider";

export const AboutUsUpdatePage = () => {
  //标题
  useDocumentTitle("关于我们", true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const aboutUsId = Number(
    pathname.match(/shop\/aboutUs\/update\/(\d+)/)?.[1]
  );

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //表单
  const [form] = useForm();
  const [aboutUsPic, setAboutUsPic] = useState("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [showParam, setShowParam] = useState(false);
  const [paramName, setParamName] = useState("");
  const { data: pageUrlList } = usePageUrlList();
  const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
  const optionChange = (pageUrlId: number) => {
    mutateAsyncPageUrl({ pageUrlId }).then((data) => {
      const flag = data.data.pageUrlFlag;
      const paramName = data.data.pageUrlParamName;
      setShowParam(flag);
      setParamName(paramName);
    });
  };
  const [text, setText] = useState("");
  //准备回显
  const { data: editingAboutUs, isLoading } = useAboutUs();

  useEffect(() => {
    setText(editingAboutUs?.text);
    form.setFieldsValue({
      textfor: BraftEditor.createEditorState(
        editingAboutUs?.data?.text
      ),
    });
  }, [editingAboutUs, form]);
  //富文本上传    //富文本
  const handleEditorChange = (editorState: any) => {
    setText(editorState.toHTML());
  };
  const myUploadFn = (param: any) => {
    const serverURL = apiUrl + "/upload/braftEditorImg";
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    const successFn = (response: any) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: xhr.responseText,
        meta: {
          loop: true, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
        },
      });
    };
    const progressFn = (event: any) => {
      // 上传进度发生变化时调用param.progress
      param.progress((event.loaded / event.total) * 100);
    };
    const errorFn = (response: any) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: "unable to upload.",
      });
    };
    xhr.upload.addEventListener("progress", progressFn, false);
    xhr.addEventListener("load", successFn, false);
    xhr.addEventListener("error", errorFn, false);
    xhr.addEventListener("abort", errorFn, false);
    fd.append("file", param.file);
    xhr.open("POST", serverURL, true);
    xhr.setRequestHeader("name", "imageFile");
    xhr.setRequestHeader("Authorization", "Bearer " + token);
    xhr.send(fd);
  };
  //新建
  const [submitLoading, setSubmitLoading] = useState(false);
  const { mutateAsync } = useEditAboutUs();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({
      ...editingAboutUs?.data,
      ...values,
      text,
    })
      .then((data) => {
        if (data.status === 0) {
          message.success("提交成功");
          setSubmitLoading(false);
          navigate("/shop/aboutUs/update");
        } else {
          setSubmitLoading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("提交失败，请重新提交");
        setSubmitLoading(false);
      });
  };

  return (
    <div>
      <Divider style={{ marginBottom: 24 }} />

      <Spin spinning={isLoading}>
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"关于我们"}
            name={"textfor"}
            style={{ marginTop: 24 }}
          >
            <BraftEditor
              style={{ border: "1px solid #f0f0f0", width: "800px" }}
              onChange={handleEditorChange}
              media={{ uploadFn: myUploadFn }}
            />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
