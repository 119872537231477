import { useMutation, useQuery, useQueryClient } from "react-query";
import { AboutUs } from "../interfaces/about-us";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";


export const useEditAboutUs = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AboutUs>) => client(`aboutUs`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('aboutUsPage')
        }
    )
}
 
export const useAddAboutUs = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AboutUs>) => client(`aboutUs`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('aboutUsPage')
        }
    )
}


export const useAboutUs = () => {
    const client = useHttp()
    return useQuery<any>(
        ['aboutUs'],
        () => client(`aboutUs`,{method:'GET'})
    )
}