import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
  Upload,
  Menu,
  Cascader,
  DatePicker,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useReport, useEditReport } from "../../services/report-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import { distpicker } from "../../utils/distpicker";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;

export const ReportUpdatePage = () => {
  //标题
  useDocumentTitle("报告详情", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const reportId = pathname.match(/report\/report-manage\/report\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };


  const [isChronicDisease, setChronicDisease] = useState(false);
  const handleChronicDiseaseChange = (e: any) => {
    setChronicDisease(e.target.value);
  };

  const [isClinicalReport, setClinicalReport] = useState(false);
  const handleClinicalReportChange = (e: any) => {
    setClinicalReport(e.target.value);
  };

  const dateFormat = "YYYY-MM-DD";
  // 出生年月
  const [birthdayTimeStr, setBirthdayTimeStr] = useState("");
  const handleSelectBirthdayTime = (value: any, dateString: any) => {
    setBirthdayTimeStr(dateString);
  };

  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingReport, isLoading } = useReport(Number(reportId));
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    form.setFieldsValue(editingReport?.data);
    const picUrl =
      imgUrl + editingReport?.data?.shopId + "/" + editingReport?.data?.advPic;
    setImageUrl(picUrl);
    form.setFieldsValue({
      uploadMore: editingReport?.data?.reportMorePicVOList,
    });


    if (editingReport?.data?.birthdayTimeStr) {
      setBirthdayTimeStr(editingReport?.data?.birthdayTimeStr);

      form.setFieldsValue({
        birthdayTimeStrs: moment(
          editingReport?.data?.birthdayTimeStr,
          "YYYY-MM-DD"
        ),
      });
    }

    //多图
    if (editingReport?.data?.reportMorePicVOList) {
      setFileList(editingReport?.data?.reportMorePicVOList);
    }


    const position = [
      editingReport?.data.reportMemberProvince,
      editingReport?.data.reportMemberCity,
      editingReport?.data.reportMemberDistrict,
    ];

    
  
    form.setFieldsValue({
      ...editingReport?.data,
      position,
    });
  }, [editingReport, form]);

  //上传近三天图片
  const [fileList, setFileList] = useState([]);
  const uploadMoreButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const handleChangeMore = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setFileList(info?.fileList);
    }
    if (info.file.status === "removed") {
      setFileList(info?.fileList);
    }
  };
  const beforeUploadMore = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };
  const normMoreFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  

  //编辑
  const useMutateEditReport = useEditReport;
  const { mutateAsync, error } = useMutateEditReport();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    const reportProvince = values?.position[0];
    const reportCity = values?.position[1];
    const reportDistrict = values?.position[2];


    mutateAsync({
      ...editingReport?.data,
      reportMemberProvince: reportProvince,
      reportMemberCity: reportCity,
      reportMemberDistrict: reportDistrict,
      // reportMemberPic,
      birthdayTime: birthdayTimeStr,
      ...values,
    }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/report/report-manage/report/list";
    });
  };
  return (
    <div>
    
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/report/report-manage/report/list"}>
              <Button type="primary">报告列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"姓名"}
            name={"reportMemberName"}
            rules={[{ required: true, message: "用户姓名必填" }]}
          >
            <Input placeholder={"请输入用户姓名"} readOnly/>
          </Form.Item>

          <Form.Item
            label={"手机号码"}
            name={"reportMemberTel"}
            rules={[{ required: true, message: "手机号码必填" }]}
          >
            <Input placeholder={"请输入手机号码"} readOnly/>
          </Form.Item>

          <Form.Item
            label={"性别"}
            name={"reportMemberSex"}
            rules={[{ required: true, message: "性别必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group disabled>
              <Radio value={"男"}>男</Radio>
              <Radio value={"女"}>女</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={"年龄(岁)"}
            name={"reportMemberAge"}
            rules={[{ required: true, message: "年龄必填" }]}
          >
            <Input placeholder={"请输入年龄"} readOnly/>
          </Form.Item>

          <Form.Item
            label={"样品编号"}
            name={"reportSampleSn"}
            rules={[{ required: true, message: "样品编号必填" }]}
          >
            <Input placeholder={"请输入样品编号"} readOnly/>
          </Form.Item>

          <Form.Item label={"现病史"} name={"reportPresentIllness"}>
                <Input.TextArea
                  rows={5} readOnly
                  style={{ width: 380 }}
                  placeholder={"请输入现病史"}
                />
              </Form.Item>

              <Form.Item label={"既往史"} name={"reportPreviousIllness"}>
                <Input.TextArea
                  rows={5} readOnly
                  style={{ width: 380 }}
                  placeholder={"请输入既往史"}
                />
              </Form.Item>

              <Form.Item label={"个人史"} name={"reportPresonalIllness"}>
                <Input.TextArea
                  rows={5} readOnly
                  style={{ width: 380 }}
                  placeholder={"请输入个人史"}
                />
              </Form.Item>

              <Form.Item label={"家庭史"} name={"reportFamilyIllness"}>
                <Input.TextArea
                  rows={5} readOnly
                  style={{ width: 380 }}
                  placeholder={"请输入家庭史"}
                />
              </Form.Item>

              <Form.Item label={"病理诊断"} name={"reportPathologicalDiagnosis"}>
                <Input.TextArea
                  rows={5} readOnly
                  style={{ width: 380 }}
                  placeholder={"请输入现病史"}
                />
              </Form.Item>

              <Form.Item label={"诊疗经过"} name={"reportTreatmentProcess"}>
                <Input.TextArea
                  rows={5} readOnly
                  style={{ width: 380 }}
                  placeholder={"请输入诊疗经过"}
                />
              </Form.Item>

          <Form.Item
            name={"uploadMore"}
            label={"报告图片"}
            valuePropName="fileList"
            getValueFromEvent={normMoreFile}
            rules={[{ required: true, message: "报告图片必传" }]}
            style={{ textAlign: "left" }}
          >
            <Upload
              name="imageFile"
              action={apiUrl + "/upload/antdImg"}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={beforeUploadMore}
              onChange={handleChangeMore}
              disabled
              headers={{ Authorization: "Bearer " + token }}
            >
              {fileList.length < 1 ? uploadMoreButton : ""}
            </Upload>
          </Form.Item>




{/*          
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item> */}
        </Form>
      </Spin>
    </div>
  );
};
