import { useMutation, useQuery, useQueryClient } from "react-query";
import { Feedback } from "../interfaces/feedback";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useFeedbackList = (param?: Partial<Feedback>) => {
    const client = useHttp();
    const result = useQuery<any>(['feedbackList', param], () => client('feedback', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditFeedback = () => {
    const client = useHttp() 
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Feedback>) => client(`feedback`, {
            method: 'PUT',
            data: params
        }), { 
            onSuccess: () => queryClient.invalidateQueries('feedbackList')
        }
    )
}

export const useDeleteFeedback = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ feedbackId }: { feedbackId: number}) =>
            client(`feedback/${feedbackId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('feedbackList')
        }
    );
};

export const useReplyState = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
      (params: Partial<Feedback>) =>
        client(`feedback/reply`, {
          data: params,
          method: 'Get',
        }),
      {
        onSuccess: () => queryClient.invalidateQueries('feedbackList'),
      }
    )
  }