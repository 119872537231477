import { Layout } from "antd";
import { SiderLayout } from "../../layouts/sider";
import { HeaderLayout } from "../../layouts/header";
import { FooterLayout } from "../../layouts/footer";

//概况
import { DashboardPage } from "../dashboard/index";
// 用户
import { MemberListPage } from "../member/list";

// 广告
import { AdvListPage } from "../adv/list";
import { AdvAddPage } from "../adv/add";
import { AdvUpdatePage } from "../adv/update";

import { AdvPageListPage } from "../adv-page/list";
import { AdvPageAddPage } from "../adv-page/add";
import { AdvPageUpdatePage } from "../adv-page/update";

// 邮箱
import { MailboxListPage } from "../mailbox/list";
import { MailboxAddPage } from "../mailbox/add";
import { MailboxUpdatePage } from "../mailbox/update";

// 关于我们
import { AboutUsUpdatePage } from "../about-us/update";

// 常见问题
import { CommonProblemUpdatePage } from "../common-problem/update"


// 常见问题
import { ReportPreviewPage } from "../report/preview"

// 报告列表
import { ReportListPage } from "../report/list";
import { ReportUpdatePage } from "../report/update";

// 报告类型
import { ReportTypeListPage } from "../report-type/list";
import { ReportTypeAddPage } from "../report-type/add";
import { ReportTypeUpdatePage } from "../report-type/update";

// 基础设置
import { SystemSettingUpdatePage } from "../system-setting/update";

import { FeedbackListPage } from "../feedback/list";

import { AdminUpdatePage } from "../admin/update";

import { Route, Routes, Navigate } from "react-router";
import { HashRouter as Router } from "react-router-dom";
const { Content } = Layout;

export const Authenticated = () => {
  return (
    <Layout>
      <Router>
        <SiderLayout />
        <Layout className="site-layout">
          <HeaderLayout />
          <Content
            style={{
              margin: "24px 16px 0",
              padding: 24,
              minHeight: "calc(100vh - 157px)",
              overflow: "initial",
              background: "#fff",
            }}
          >
            <Routes>
              {/* 概况 */}
              <Route path={"/dashboard"} element={<DashboardPage />} />
          
       

              {/* 用户 */}
              <Route
                path={"/member/member-manage/list"}
                element={<MemberListPage />}
              />

    {/* 邮箱 */}
    <Route
                path={"/member/member-manage/list"}
                element={<MemberListPage />}
              />


<Route
                path={"/shop/adv/list"}
                element={<AdvListPage />}
              />
              <Route
                path={"/shop/adv/add"}
                element={<AdvAddPage />}
              />
              <Route
                path={"/shop/adv/update/:advId"}
                element={<AdvUpdatePage />}
              />

<Route
                path={"/shop/advPage/list"}
                element={<AdvPageListPage />}
              />
              <Route
                path={"/shop/advPage/add"}
                element={<AdvPageAddPage />}
              />
              <Route
                path={"/shop/advPage/update/:advPageId"}
                element={<AdvPageUpdatePage />}
              />


              
<Route
                path={"/shop/feedback/list"}
                element={<FeedbackListPage />}
              />




<Route
                path={"/shop/aboutUs/update"}
                element={<AboutUsUpdatePage />}
              />

<Route
                path={"/shop/commonProblem/update"}
                element={<CommonProblemUpdatePage />}
              />

<Route
                path={"/report/report-manage/report/list"}
                element={<ReportListPage />}
              />
              <Route
                path={"/report/report-manage/report/update/:reportId"}
                element={<ReportUpdatePage />}
              />

<Route
                path={"/report/report-manage/report/preview/:reportId"}
                element={<ReportPreviewPage />}
              />


<Route
                path={"/report/report-manages/reportType/list"}
                element={<ReportTypeListPage />}
              />
              <Route
                path={"/report/report-manages/reportType/add"}
                element={<ReportTypeAddPage />}
              />
              <Route
                path={"/report/report-manages/reportType/update/:reportTypeId"}
                element={<ReportTypeUpdatePage />}
              />



<Route
                path={"/setting/mailbox/list"}
                element={<MailboxListPage />}
              />
              <Route
                path={"/setting/mailbox/add"}
                element={<MailboxAddPage />}
              />
              <Route
                path={"/setting/mailbox/update/:mailboxId"}
                element={<MailboxUpdatePage />}
              />
<Route
                path={"/setting/systemSetting"}
                element={<SystemSettingUpdatePage />}
              />

<Route
                path={"/setting/admin/updatePassword"}
                element={<AdminUpdatePage />}
              />


              <Route index element={<Navigate to={"/dashboard"} />}></Route>
            </Routes>
          </Content>
          <FooterLayout />
        </Layout>
      </Router>
    </Layout>
  );
};
