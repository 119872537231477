import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddMailbox } from "../../services/mailbox-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import {
  useReportTypeListAll,
} from "../../services/report-type-service";
const { Option } = Select;

export const MailboxAddPage = () => {
  //标题
  useDocumentTitle("新建邮箱", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
    // 报告类型
    const { data: reportTypeList } = useReportTypeListAll();
    const reportTypeOptions = reportTypeList?.map((item: any) => (
      <Option value={item?.reportTypeId} key={item.reportTypeId}>{item?.reportTypeName}</Option>
    ));
  
  //准备跳转链接
  const [propertyShow, setPropertyShow] = useState(false);
  const [propertyName, setPropertyName] = useState("参数");
  const { data: pageUrlList } = usePageUrlList();
  const options = pageUrlList?.map((item: any, value: any) => (
    <Option value={item.pageUrlId} key={item.pageUrlId}>
      {item.pageUrlName}
    </Option>
  ));
  //option选中与非选中
  const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
  function optionHandleChange(pageUrlId: number) {
    mutateAsyncPageUrl({ pageUrlId }).then((data) => {
      const pageUrlFlag = data.data.pageUrlFlag;
      const pageUrlPropertyName = data.data.pageUrlPropertyName;
      setPropertyShow(pageUrlFlag);
      setPropertyName(pageUrlPropertyName);
    });
  }
  //表单
  const [form] = useForm();
  //图片上传
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        mailboxPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //新建
  const useMutateAddMailbox = useAddMailbox;
  const { mutateAsync, error } = useMutateAddMailbox();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/setting/mailbox/list";
    });
  };
  return (
    <div>
      <Breadcrumb style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to={"/setting/mailbox/list"}>邮箱列表</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>新建邮箱</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col span={12}>
          <Link to={"/setting/mailbox/list"}>
            <Button type="primary">邮箱列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          mailboxDefault: true,
          mailboxUse: true,
        }}
      >
        <Form.Item
          label={"邮箱号"}
          name={"mailboxNumber"}
          rules={[{ required: true, message: "邮箱号必填" }]}
        >
          <Input placeholder={"请输入邮箱号"} />
        </Form.Item>

        <Form.Item 
                    label={"绑定报告"}>
                   
                        <Form.Item
                            name={"reportTypeId"}
                            noStyle
                        >
                            <Select
                                showSearch
                                placeholder="请选择报告类型"
                                optionFilterProp="children"
                                style={{textAlign:'left'}}
                            >
                                <Option value={0} key={0}>请选择</Option>
                                {reportTypeOptions}
                            </Select>
                        </Form.Item> 
                      
                  
                </Form.Item>

        <Form.Item
          label={"备注"}
          name={"mailboxRemark"}
        >
          <Input placeholder={"请输入备注"} />
        </Form.Item>


        <Form.Item
          label={"是否默认"}
          name={"mailboxDefault"}
          rules={[{ required: true, message: "是否默认必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              是
            </Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={"是否使用"}
          name={"mailboxUse"}
          rules={[{ required: true, message: "是否使用必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              是
            </Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
