import { useMutation, useQuery, QueryKey, useQueryClient } from "react-query";
import { Admin } from "../interfaces/admin";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

//列表
export const useAdminList = (param?: Partial<Admin>) => {
    const client = useHttp();
    const result = useQuery<any>(['adminPage', param], () => client('admin', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

//新增
export const useAddAdmin = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation((params: Partial<Admin>) =>
        client(`admin`, {
            data: params,
            method: 'POST',
        }), {
            onSuccess: () => queryClient.invalidateQueries('adminPage')
        }
    );
};

//编辑
export const useEditAdmin = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation((params: Partial<Admin>) =>
        client(`admin`, {
            data: params,
            method: 'PUT',
        }), {
            onSuccess: () => queryClient.invalidateQueries('adminPage')
        }
    );
};

//删除
export const useDeleteadmin = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation(({ adminId }: { adminId: number }) =>
        client(`admin/${adminId}`, {
            method: 'DELETE',
        }), {
        onSuccess: () => queryClient.invalidateQueries('adminPage')
    }
    );
};

//详情
export const useAdmin = (adminId?: number) => {
    const client = useHttp();
    return useQuery<any>(['adminDetail', { adminId }], () => client(`admin/${adminId}`), {
        enabled: Boolean(adminId),
    });
};

//修改密码
export const useEditAdminPwd = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Admin>) => client(`admin/updatePwd`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('adminPage')
    }
    )
}

//重置密码
export const useResetAdmin = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation((params: Partial<Admin>) =>
        client(`admin/reset`, {
            data: params,
            method: 'PUT',
        }), {
            onSuccess: () => queryClient.invalidateQueries('adminPage')
        }
    );
};