import { useMutation, useQuery, useQueryClient } from "react-query";
import { Adv } from "../interfaces/adv";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAdvList = (param?: Partial<Adv>) => {
    const client = useHttp();
    const result = useQuery<any>(['advList', param], () => client('adv', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Adv>) => client(`adv`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('advList')
        }
    )
}

export const useAddAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Adv>) => client(`adv`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('advList')
        }
    )
}

export const useDeleteAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ advId }: { advId: number}) =>
            client(`adv/${advId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('advList')
        }
    );
};

export const useAdv = (advId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['adv', {advId}],
        () => client(`adv/${advId}`),
        {
            enabled: Boolean(advId)
        }
    )
}