import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdvPage } from "../interfaces/adv-page";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAdvPageList = (param?: Partial<AdvPage>) => {
    const client = useHttp();
    const result = useQuery<any>(['advPageList', param], () => client('advPage', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditAdvPage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AdvPage>) => client(`advPage`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('advPageList')
        }
    )
}

export const useAddAdvPage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AdvPage>) => client(`advPage`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('advPageList')
        }
    )
}

export const useDeleteAdvPage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ advPageId }: { advPageId: number}) =>
            client(`advPage/${advPageId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('advPageList')
        }
    );
};

export const useAdvPage = (advPageId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['advPage', {advPageId}],
        () => client(`advPage/${advPageId}`),
        {
            enabled: Boolean(advPageId)
        }
    )
}