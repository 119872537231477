import { Button, Col, message, Modal, Row, Space, Table, Image, Breadcrumb } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useAdvPageList, useDeleteAdvPage } from "../../services/adv-page-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAdvPageSearchParams } from "./util"

export const AdvPageListPage = () => {
    useDocumentTitle('轮播列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL
    const [param, setParam] = useAdvPageSearchParams()
    const {isLoading, error, totalElements, data: list} = useAdvPageList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteAdvPage = useDeleteAdvPage;
    const { mutateAsync } = useMutateDeleteAdvPage();
    const confirmDeleteAdvPage = (advPageId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ advPageId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div> 
            <Breadcrumb style={{ marginBottom: 24 }}>
                <Breadcrumb.Item>轮播列表</Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{marginBottom: 24}}> 
                <Col span={12}>
                    <Link to={"/shop/advPage/add"}>
                        <Button type="primary">新建轮播</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search
                        placeholder="可搜索轮播标题"
                        style={{ width: 200 }}
                        value={param.advPageTitle}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            advPageTitle: evt.target.value,
                            pageNum: null
                        })}
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            advPageTitle: value,
                            pageNum: null
                        })}
                    />
                </Col>
            </Row>
            <Table
                loading = { isLoading }
                rowKey={ columns => columns.advPageId }
                pagination = { paginationProps }
                columns = {[
                {
                    title: '轮播ID',
                    dataIndex: 'advPageId',
                    key: 'advPageId',
                    width: 100,
                },
                {
                    title: '轮播标题',
                    dataIndex: 'advPageTitle',
                    key: 'advPageTitle',
                },
                {
                    title: '轮播图片',
                    dataIndex: 'advPagePic',
                    key: 'advPagePic',
                    render: (value, advPage) => (
                        <Image width={150} src={imgUrl + advPage.advPagePic}/>
                    ),
                },
                {
                    title: '轮播排序',
                    dataIndex: 'advPageSort',
                    key: 'advPageSort',
                },
                {
                    title: '是否显示',
                    dataIndex: 'advPageShow',
                    key: 'advPageShow',
                    render: (value, advPage) => (
                        <span>{advPage.advPageShow ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, advPage) => (
                        <Space size="middle">
                            <Link to={"/shop/advPage/update/" + advPage.advPageId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteAdvPage(advPage.advPageId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}
