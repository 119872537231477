import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Radio,
  Row,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useReportType,
  useEditReportType,
} from "../../services/report-type-service";
import { useDocumentTitle } from "../../utils";

export const ReportTypeUpdatePage = () => {
  //标题
  useDocumentTitle("编辑报告类型", true);
  const { pathname } = useLocation();
  const reportTypeId = pathname.match(
    /report\/report-manages\/reportType\/update\/(\d+)/
  )?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingReportType, isLoading } = useReportType(
    Number(reportTypeId)
  );
  useEffect(() => {
    form.setFieldsValue(editingReportType?.data);
  }, [editingReportType, form]);
  //编辑
  const useMutateEditReportType = useEditReportType;
  const { mutateAsync, error } = useMutateEditReportType();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingReportType?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/report/report-manages/reportType/list";
    });
  };
  return (
    <div>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/report/report-manages/reportType/list"}>
              <Button type="primary">类型列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"类型名称"}
            name={"reportTypeName"}
            rules={[{ required: true, message: "类型名称必填" }]}
          >
            <Input placeholder={"请输入类型名称"} />
          </Form.Item>

          <Form.Item
            label={"类型排序"}
            name={"reportTypeSort"}
            rules={[{ required: true, message: "类型排序必填" }]}
          >
            <Input placeholder={"请输入类型排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"reportTypeShow"}
            rules={[{ required: true, message: "类型显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
