import { Layout, Row, Collapse, Image } from "antd";
import {
  BarChartOutlined,
  ProfileOutlined,
  UserOutlined,
  ShopOutlined,
  AccountBookOutlined,
  FundOutlined,
  TagOutlined,
  AppstoreOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  SkinOutlined,
  BarcodeOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import logo from "../assets/inner-logo.png";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import React, { useEffect, useState } from "react";
import "../layouts/sider.css";
const { Panel } = Collapse;
const { Sider } = Layout;
const authorityList = {
  status: 0,
  msg: "查询成功",
  data: [
    {
      authorityId: 1,
      authorityUrl: null,
      authorityParentId: 0,
      authorityName: "装饰",
      authoritySort: 0,
      authorityKey: "shop",
      authorityLogo: "",
      check: null,
      lowerAuthorityList: [
        {
          authorityId: 11,
          authorityUrl: "/shop/adv/list",
          authorityParentId: 1,
          authorityName: "广告管理",
          authoritySort: 0,
          authorityKey: "adv",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 12,
          authorityUrl: "/shop/aboutUs/update",
          authorityParentId: 1,
          authorityName: "关于我们",
          authoritySort: 0,
          authorityKey: "aboutUs",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 13,
          authorityUrl: "/shop/commonProblem/update",
          authorityParentId: 1,
          authorityName: "常见问题",
          authoritySort: 0,
          authorityKey: "commonProblem",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 14,
          authorityUrl: "/shop/feedback/list",
          authorityParentId: 1,
          authorityName: "反馈管理",
          authoritySort: 0,
          authorityKey: "feedback",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 15,
          authorityUrl: "/shop/advPage/list",
          authorityParentId: 1,
          authorityName: "常用功能图",
          authoritySort: 0,
          authorityKey: "advPage",
          authorityLogo: null,
          check: null,
        },
      ],
    },
    {
      authorityId: 2,
      authorityUrl: null,
      authorityParentId: 0,
      authorityName: "报告",
      authoritySort: 0,
      authorityKey: "report",
      authorityLogo: "",
      check: null,
      lowerAuthorityList: [
        {
          authorityId: 21,
          authorityUrl: "/report/report-manage/report/list",
          authorityParentId: 2,
          authorityName: "报告管理",
          authoritySort: 0,
          authorityKey: "report-manage",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 22,
          authorityUrl: "/report/report-manages/reportType/list",
          authorityParentId: 2,
          authorityName: "报告类型",
          authoritySort: 0,
          authorityKey: "report-manages",
          authorityLogo: null,
          check: null,
        },
      ],
    },
    {
      authorityId: 3,
      authorityUrl: null,
      authorityParentId: 0,
      authorityName: "用户",
      authoritySort: 0,
      authorityKey: "member",
      authorityLogo: "",
      check: null,
      lowerAuthorityList: [
        {
          authorityId: 31,
          authorityUrl: "/member/member-manage/list",
          authorityParentId: 3,
          authorityName: "用户列表",
          authoritySort: 0,
          authorityKey: "member-manage",
          authorityLogo: null,
          check: null,
        },
      ],
    },
    {
      authorityId: 4,
      authorityUrl: null,
      authorityParentId: 0,
      authorityName: "设置",
      authoritySort: 41,
      authorityKey: "setting",
      authorityLogo: null,
      check: null,
      lowerAuthorityList: [
        {
          authorityId: 41,
          authorityUrl: "/setting/systemSetting",
          authorityParentId: 4,
          authorityName: "基础设置",
          authoritySort: 99,
          authorityKey: "systemSetting",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 42,
          authorityUrl: "/setting/mailbox/list",
          authorityParentId: 4,
          authorityName: "邮箱列表",
          authoritySort: 99,
          authorityKey: "mailbox",
          authorityLogo: null,
          check: null,
        },
        {
          authorityId: 43,
          authorityUrl: "/setting/admin/updatePassword",
          authorityParentId: 4,
          authorityName: "修改密码",
          authoritySort: 99,
          authorityKey: "admin",
          authorityLogo: null,
          check: null,
        },
      ],
    },
  ],
};
export const SiderLayout = () => {
  // const { storeAccount } = useAuth();
  // const { data: authorityList } = useAuthorityList() // 接口数据
  const { pathname } = useLocation();
  const paths = pathname.split("/");
  const selectedKeys1 = paths[1];
  const selectedKeys2 = paths[2];
  const selectedKeys3 = paths[3];
  //鼠标移入
  const [itemObj, setItemObj] = useState<any>(); //下级对象
  const [authorityKey, setAuthorityKey] = useState<any>();
  const onMouseOver = (obj: any) => {
    if (obj) {
      setItemObj({ ...obj });
      setAuthorityKey(obj.authorityKey);
    } else {
      setItemObj("");
      setAuthorityKey("");
    }
  };
  const onMouseOverTwo = () => {
    authorityList?.data?.forEach((item) => {
      if (item.authorityKey === selectedKeys1) {
        setItemObj({ ...item });
      }
      setAuthorityKey("");
    });
  };
  //   鼠标移开
  const onMouseLeave = () => {
    if (selectedKeys1 && selectedKeys2) {
      authorityList?.data?.forEach((item) => {
        if (item.authorityKey === selectedKeys1) {
          setItemObj({ ...item });
        }
      });
    } else {
      setItemObj("");
    }
    setAuthorityKey("");
  };
  useEffect(() => {
    console.log(selectedKeys1, selectedKeys2, selectedKeys3);
    if (selectedKeys1) {
      authorityList?.data?.forEach((item) => {
        if (item.authorityKey && item.authorityKey === selectedKeys1) {
          setItemObj({ ...item });
        }
      });
    } else {
      setItemObj("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, authorityList]);
  return (
    <Sider width={itemObj && selectedKeys2 ? 220 : 90}>
      <Row>
        <aside
          id="shared-sidebar"
          onMouseLeave={onMouseLeave}
          className="super-store"
        >
          <nav role="main" id="shared-first-sidebar">
            <div id="shared-team-logo">
              <Image src={logo} width={30} height={30} />
            </div>
            <ul id="shared-first-sidebar-nav">
              <li
                onMouseOver={onMouseOverTwo}
                className={selectedKeys1 === "dashboard" ? "active" : ""}
              >
                <Link to={"/dashboard"}>
                  <FundOutlined style={{ marginRight: 5 }} />
                  概况
                </Link>
              </li>
              {authorityList?.data?.map((item: any) => (
                <li
                  className={
                    item.authorityKey === selectedKeys1 ? "active" : ""
                  }
                  id={authorityKey === item?.authorityKey ? "authorityKey" : ""}
                  onMouseOver={() => onMouseOver(item)}
                  key={item.authorityId}
                >
                  <Link
                    to={
                      item.lowerAuthorityList &&
                      item?.lowerAuthorityList[0].authorityUrl
                        ? item?.lowerAuthorityList[0].authorityUrl
                        : item?.lowerAuthorityList[0].lowerAuthorityList[0]
                            .authorityUrl
                    }
                  >
                    {item.authorityKey === "member" ? (
                      <UserOutlined style={{ marginRight: 5 }} />
                    ) : item.authorityKey === "setting" ? (
                      <SettingOutlined style={{ marginRight: 5 }} />
                    ) : item.authorityKey === "shop" ? (
                      <FileProtectOutlined style={{ marginRight: 5 }} />
                    ) : item.authorityKey === "report" ? (
                      <ProfileOutlined style={{ marginRight: 5 }} />
                    ) : (
                      ""
                    )}
                    {item.authorityName}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          {itemObj ? (
            <nav id="shared-second-sidebar" className="shared-second-sidebar">
              <h2 className="second-sidebar-title">{itemObj?.authorityName}</h2>
              <ul
                className="shared-second-sidebar-nav"
                style={{ visibility: "visible" }}
              >
                <Collapse
                  defaultActiveKey={[
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ]}
                  ghost
                >
                  {itemObj?.lowerAuthorityList?.map((item: any, index: any) =>
                    item?.lowerAuthorityList?.length ? (
                      <Panel
                        style={{ padding: 0 }}
                        header={item?.authorityName}
                        key={index}
                      >
                        {item?.lowerAuthorityList?.map((itemi: any) => (
                          <li
                            className={
                              selectedKeys2 === itemi.authorityKey
                                ? "active second-nav-item"
                                : "second-nav-item"
                            }
                            key={itemi.authorityId}
                          >
                            <Link to={itemi?.authorityUrl}>
                              {itemi?.authorityName}
                            </Link>
                          </li>
                        ))}
                      </Panel>
                    ) : (
                      <li
                        className={
                          selectedKeys2 === item.authorityKey
                            ? "active second-nav-item"
                            : "second-nav-item"
                        }
                        key={item.authorityId}
                      >
                        <Link to={item?.authorityUrl}>
                          {item?.authorityName}
                        </Link>
                      </li>
                    )
                  )}
                </Collapse>
                <div className="divider"></div>
              </ul>
            </nav>
          ) : (
            ""
          )}
          <div id="shared-second-sidebar-toggle"></div>
        </aside>
      </Row>
    </Sider>
    // <Sider
    //   width='180'
    //   style={{
    //     overflow: 'auto',
    //     height: '100vh',
    //     position: 'fixed',
    //     left: 0,
    //   }}>
    //   <Row>
    //     <div
    //       className='logo'
    //       style={{
    //         height: '32px',
    //         margin: '16px',
    //         marginLeft: '75px',
    //         textAlign: 'center',
    //       }}>
    //       <Image src={logo} width={32} height={32} />
    //     </div>
    //   </Row>
    //   <Row>
    //     <Menu
    //       theme='dark'
    //       mode='inline'
    //       openKeys={openKeys}
    //       onOpenChange={onOpenChange}
    //       defaultSelectedKeys={[selectedKeys1, selectedKeys2, selectedKeys3, selectedKeys4, selectedKeys5]}>
    //       <Menu.Item key='dashboard' icon={<FundOutlined />}>
    //         <Link to='/dashboard'>概况</Link>
    //       </Menu.Item>
    //       <SubMenu key='shop' icon={<ShopOutlined />} title='店铺'>
    //         <SubMenu key='shop1' title='首页装饰'>
    //           <Menu.Item key='adv'>
    //             <Link to='/shop/adv/list'>广告管理</Link>
    //           </Menu.Item>
    //           <Menu.Item key='navigation'>
    //             <Link to='/shop/navigation/list'>导航管理</Link>
    //           </Menu.Item>
    //           <Menu.Item key='subject'>
    //             <Link to='/shop/subject/list'>专题管理</Link>
    //           </Menu.Item>
    //           <Menu.Item key='floor'>
    //             <Link to='/shop/floor/list'>楼层管理</Link>
    //           </Menu.Item>
    //           <Menu.Item key='showcase'>
    //             <Link to='/shop/showcase/list'>橱窗管理</Link>
    //           </Menu.Item>
    //           <Menu.Item key='bombWindow'>
    //             <Link to='/shop/bombWindow/list'>开屏广告</Link>
    //           </Menu.Item>
    //         </SubMenu>
    //         <SubMenu key='shop2' title='系统装饰'>
    //           <Menu.Item key='feature'>
    //             <Link to='/shop/feature/list'>微页面</Link>
    //           </Menu.Item>
    //           <Menu.Item key='menu'>
    //             <Link to='/shop/menu/list'>店铺菜单</Link>
    //           </Menu.Item>
    //           <Menu.Item key='search'>
    //             <Link to='/shop/search/hot/list'>搜索设置</Link>
    //           </Menu.Item>
    //           <Menu.Item key='personMenu'>
    //             <Link to='/shop/personMenu/list'>个人中心</Link>
    //           </Menu.Item>
    //           <Menu.Item key='goodsClassTemplate'>
    //             <Link to='/shop/goodsClassTemplate'>分类模版</Link>
    //           </Menu.Item>
    //           <Menu.Item key='indexTemplate'>
    //             <Link to='/shop/indexTemplate'>首页模版</Link>
    //           </Menu.Item>
    //         </SubMenu>
    //         <SubMenu key='shop3' title='店铺风格'>
    //           <Menu.Item key='style'>
    //             <Link to='/shop/style'>店铺色系</Link>
    //           </Menu.Item>
    //         </SubMenu>
    //       </SubMenu>
    //       <SubMenu key='goods' icon={<TagOutlined />} title='商品'>
    //         <Menu.Item key='goodsManage'>
    //           <Link to='/goods/goodsManage/list'>全部商品</Link>
    //         </Menu.Item>
    //         <Menu.Item key='class'>
    //           <Link to='/goods/class/list'>商品分类</Link>
    //         </Menu.Item>
    //         <Menu.Item key='tag'>
    //           <Link to='/goods/tag/list'>商品标签</Link>
    //         </Menu.Item>
    //         <Menu.Item key='spec'>
    //           <Link to='/goods/spec/list'>商品规格</Link>
    //         </Menu.Item>
    //         <Menu.Item key='recommed'>
    //           <Link to='/goods/recommed/list'>推荐商品</Link>
    //         </Menu.Item>
    //         <Menu.Item key='evaluate'>
    //           <Link to='/goods/evaluate/list'>商品评价</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //       <SubMenu key='orders' icon={<ProfileOutlined />} title='订单'>
    //         <Menu.Item key='ordersManage'>
    //           <Link to='/orders/ordersManage/list'>订单列表</Link>
    //         </Menu.Item>
    //         {/* <Menu.Item key="batchDelivery">
    //                         <Link to='/orders/batchDelivery'>批量发货</Link>
    //                     </Menu.Item>  */}
    //         <Menu.Item key='refund'>
    //           <Link to='/orders/refund/list'>售后维权</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //       <SubMenu key='member' icon={<UserOutlined />} title='客户'>
    //         <Menu.Item key='memberManage'>
    //           <Link to='/member/memberManage/list'>客户列表</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //       <SubMenu key='asset' icon={<AccountBookOutlined />} title='资产'>
    //         <Menu.Item key='bill'>
    //           <Link to='/asset/bill/list'>对账单</Link>
    //         </Menu.Item>
    //         <Menu.Item key='billDetail'>
    //           <Link to='/asset/billDetail/list'>对账单明细</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //       <SubMenu key='stat' icon={<BarChartOutlined />} title='数据'>
    //         <Menu.Item key='memberAnalysis'>
    //           <Link to='/stat/memberAnalysis'>客户分析</Link>
    //         </Menu.Item>
    //         <Menu.Item key='ordersAnalysis'>
    //           <Link to='/stat/ordersAnalysis'>交易分析</Link>
    //         </Menu.Item>
    //         <Menu.Item key='goodsAnalysis'>
    //           <Link to='/stat/goodsAnalysis'>商品分析</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //       <SubMenu key='application' icon={<AppstoreOutlined />} title='应用'>
    //         <Menu.Item key='coupon'>
    //           <Link to='/application/coupon/list'>优惠券</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //       <SubMenu key='setting' icon={<SettingOutlined />} title='设置'>
    //         <Menu.Item key='shopAccount'>
    //           <Link to='/setting/shopAccount/list'>账号列表</Link>
    //         </Menu.Item>
    //         <Menu.Item key='changePwd'>
    //           <Link to='/setting/shopAccount/update'>修改密码</Link>
    //         </Menu.Item>
    //       </SubMenu>
    //     </Menu>
    //   </Row>
    // </Sider>
  );
};
