import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Menu,
  message,
  Radio,
  Row,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddReportType } from "../../services/report-type-service";
import { useDocumentTitle } from "../../utils"; 

export const ReportTypeAddPage = () => {
  //标题
  useDocumentTitle("新建报告类型", true);
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //新建
  const useMutateAddReportType = useAddReportType;
  const { mutateAsync, error } = useMutateAddReportType();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/report/report-manages/reportType/list";
    });
  };
  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/report/report-manages/reportType/list"}>
            <Button type="primary">类型列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          reportTypeSort: 0,
          reportTypeShow: true,
        }}
      >
        <Form.Item
          label={"类型名称"}
          name={"reportTypeName"}
          rules={[{ required: true, message: "类型名称必填" }]}
        >
          <Input placeholder={"请输入类型名称"} />
        </Form.Item>

        <Form.Item
          label={"类型排序"}
          name={"reportTypeSort"}
          rules={[{ required: true, message: "类型排序必填" }]}
        >
          <Input placeholder={"请输入类型排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"reportTypeShow"}
          rules={[{ required: true, message: "类型显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              显示
            </Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
