import {
  Button,
  Col,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Table,
  Card,
  Form,
  Input,
  Select,
  Popconfirm,
  DatePicker,
  Dropdown,
} from "antd";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { useRef,useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import { useForm } from "antd/lib/form/Form";
import {
  useReportList,
  useDeleteReport,
  useAgreeState,
  useConfirmSend,
} from "../../services/report-service";
import {
  useMailboxListAll,
  useMailboxDefault,
  useMailboxDefaults
} from "../../services/mailbox-service";

import {
  useReportTypeListAll,
} from "../../services/report-type-service";

import { useDebounce, useDocumentTitle } from "../../utils";
import { useReportSearchParams } from "./util";
import dayjs from "dayjs";
const { Option } = Select;
const { RangePicker } = DatePicker;

export const ReportListPage = () => {
  useDocumentTitle("报告列表", true);
  const [param, setParam] = useReportSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useReportList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };

  // 报告类型
  const { data: reportTypeList } = useReportTypeListAll();
  const reportTypeOptions = reportTypeList?.map((item: any) => (
    <Option value={item?.reportTypeId} key={item.reportTypeId}>{item?.reportTypeName}</Option>
  ));


  // 选择数据
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const [modalLoading, setModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitGoodsLoading, setSubmitGoodsLoading] = useState(false);
  // 选择的多选框，产品id
  let selectedRowKeyList: any = [];
  const onSelectChange = (selectedRowKey: any) => {
    for (let index = 0; index < selectedRowKey.length; index++) {
      const item = selectedRowKey[index];
      selectedRowKeyList.push(item);
    }
    console.log("selectedRowKeys changed: ", selectedRowKeyList);
    setSelectedRowKeys([...selectedRowKeyList]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //删除事件
  const useMutateDeleteReport = useDeleteReport;
  const { mutateAsync } = useMutateDeleteReport();
  const confirmDeleteReport = (reportId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ reportId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //同意
  const useMutateAgreeState = useAgreeState;
  const { mutateAsync: mutateUpdateState } = useMutateAgreeState();
  const confirmAgreeState = (reportId: number) => {
    Modal.confirm({
      title: "确定已解读?",
      content: "点击同意",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateUpdateState({ reportId }).then(() => {
            message.info("成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4 },
  };

  const rangeConfig = {
    rules: [{ type: "array" as const, message: "请选择时间!" }],
  };

    //准备邮件
    const { data: mailboxList } = useMailboxListAll();
    const mailboxOptions = mailboxList?.map(
      (item: any, value: any) => (
        <Option value={item.mailboxId} key={item.mailboxId}>
          {item.mailboxNumber + "(" + item.mailboxRemark + ")"}
        </Option>
      )
    );

    
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const handleSelectTime = (value: any, dateString: any) => {
    setStartTime(dateString[0]);
    setEndTime(dateString[1]);
  };

  const handleCancel1 = () => {
    setVisible(false);
   
  };

  const opMenu: any = (report: any) => {
    return (
      <Menu>
       
                  <Menu.Item>
                  <a onClick={() => showModal(report.reportId)}>
                     发送邮件
                   </a>
                </Menu.Item>
            
          {report.reportState == 10 ? 
                  
                      <Menu.Item>
                     <a onClick={() => confirmAgreeState(report.reportId)}>
                     确认已解读
                   </a>        
                    </Menu.Item>
                   :""
                  }

                  
      </Menu>
    )
  }

   //准备回显
   const { data: editingMailboxDefault } = useMailboxDefault();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [reportId, setReportId] = useState<number>();
  const [type, setType] = useState<number>(0);
  const [mailboxId, setMailboxId] = useState<number>(editingMailboxDefault?.data?.mailboxId);
  const handleMailbox = (value: any) => {
    setMailboxId(value);
    setType(1)
   };

  const showModal = (reportId: any) => {
      setVisible(true);
      setReportId(reportId);
    };

    const useMutateConfirmSend = useConfirmSend;
    const { mutateAsync: mutateAsyncConfirmSend } = useMutateConfirmSend();

    const useMutateMailboxDefault = useMailboxDefaults;
    const { mutateAsync: mutateAsyncMailboxDefault } = useMutateMailboxDefault();

    //提交
const onFinishBill = (values:any) => {
   setSubmitLoading(true);
   mutateAsyncConfirmSend({ reportId,mailboxId:mailboxId}).then(() => {
     message.info("发送成功");
     setSubmitLoading(false); 
     setVisible(false);
   });


 };

 useEffect(() => {
  if(type == 0){
  mutateAsyncMailboxDefault({}).then((data:any) => {
      console.log(data?.data?.mailboxId)
      setMailboxId(data?.data?.mailboxId);
  
  });}
  
}, [mailboxId]);


 const [form4] = useForm();

  //提交
  const onFinish = (values: any) => {
    console.log(values.reportTypeId)
    setParam({
      ...param,
      reportState: values.reportState,
      reportMemberName: values.reportMemberName,
      reportMemberTel: values.reportMemberTel,
      startTime: startTime,
      endTime: endTime,
      reportSn: values.reportSn,
      reportSampleSn : values.reportSampleSn,
      reportTypeId : values.reportTypeId,
      pageNum: null,
    });
  };
  //分页
  return (
    <div>
      
      <Card style={{ marginBottom: 24, backgroundColor: "#f2f3f5" }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="reportMemberName" label="姓名">
                <Input placeholder="请输入姓名"  style={{ width: 448}}/>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="reportMemberTel" label="手机号码">
                <Input placeholder="请输入手机号码" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="reportSn" label="报告编号">
                <Input placeholder="请输入报告编号" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="reportState" label="状态">
                <Select
                  style={{ width: 448}}
                  showSearch
                  placeholder="请选择状态"
                  optionFilterProp="children"
                >
                  <Option value="">全部</Option>
                  <Option value={10}>待解读</Option>
                  <Option value={20}>已解读</Option>
                
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="reportSampleSn" label="样品编号">
                <Input placeholder="请输入样品编号" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="reportAddTime" label="提交时间" {...rangeConfig}>
                <RangePicker
                  onChange={handleSelectTime}
                  style={{ width: 420 }}
                  showTime
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
          <Col span={8}>
              <Form.Item name="reportTypeId" label="类型">
                <Select
                  showSearch
                  placeholder="请选择报告类型"
                  style={{ width: 448}}
                  optionFilterProp="children"
                  allowClear
                >
                  {reportTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            </Row>
          <Form.Item>
            <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Table
        loading={isLoading}
        rowKey={(columns) => columns.reportId}
        pagination={paginationProps}
        columns={[
          {
            title: "报告ID",
            dataIndex: "reportId",
            key: "reportId",
            width: 100,
            align:"center",
          },
          {
            title: '报告类型',
            dataIndex: 'reportTypeName',
            key: 'reportTypeName',
        },
          {
            title: "编号",
            dataIndex: "reportSn",
            key: "reportSn",
            align:"center",
          },
          {
            title: "状态",
            dataIndex: "reportState",
            key: "reportState",
            align:"center",
            render(value, report) {
              return (
                <span>
                  {report.reportState == 10
                    ? "待解读"
                    : report.reportState == 20
                    ? "已解读"
                    : ""}
                </span>
              );
            },
          },
          {
            title: "姓名",
            dataIndex: "reportMemberName",
            key: "reportMemberName",
            align:"center",
          },
          {
            title: "手机号码",
            dataIndex: "reportMemberTel",
            key: "reportMemberTel",
            align:"center",
          },
          {
            title: "样品编号",
            dataIndex: "reportSampleSn",
            key: "reportSampleSn",
            align:"center",
          },
          {
            title: "是否已发过邮件",
            dataIndex: "reportSend",
            key: "reportSend",
            align:"center",
            render(value, report) {
              return (
                <span>
                  {report.reportSend == true
                    ? "是"
                    : report.reportSend == false
                    ? "否"
                    : ""}
                </span>
              );
            },
          },
          {
            title: "提交时间",
            dataIndex: "reportAddTime",
            key: "reportAddTime",
            align:"center",
            render(value, report) {
              return (
                <span>
                  {report.reportAddTime
                    ? dayjs(report.reportAddTime).format("YYYY-MM-DD HH:mm")
                    : "无"}
                </span>
              );
            },
          },
          {
            title: "操作",
            key: "action",
            render: (value, report) => (
              // <Space size="middle">
              // {report?.reportState == 1? (
              //   <a onClick={() => confirmAgreeState(report.reportId)}>
              //     同意
              //   </a>
              //   <a onClick={() => confirmRejectState(report.reportId)}>
              //   拒绝
              //   </a>
              // ) : (
              //   ""
              // )}</Space>
              <Space>
               

                <Dropdown overlay={opMenu.bind(this, report)}>
                  <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                    操作 <DownOutlined />
                  </a>
                </Dropdown>

                


                <Space size="middle" style={{marginLeft:20}}>
                  <Link to={"/report/report-manage/report/update/" + report.reportId}>
                    详情
                  </Link>
                </Space>

                {report?.reportFile ? (
                  <Link to={"/report/report-manage/report/preview/" + report.reportId}>
                    <div style={{marginLeft:20}}>查看报告</div>
                  </Link>
                ) : (
                  ""
                )}

              </Space>
            ),
            width: 270,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
      
      <Modal
        footer={null}
        confirmLoading={submitLoading}
        centered
        bodyStyle={{ height: "100", overflowY: "auto" }}
       
        width={888}
        title="发送邮件"
        visible={visible}
        // onOk={handleOk}
        onCancel={handleCancel1}
      >
      
          <Form
            form={form4}
            {...layout}
            name="basic"
            onFinish={onFinishBill}
          >
            <Form.Item name={"reportId"} style={{ textAlign: "left" }} hidden>
              <Input style={{ width: 380 }} />
            </Form.Item>

            <Form.Item
            label={"收件人邮箱"}
            name={"mailboxId"}
            // rules={[{ required: true, message: "收件人邮箱必选" }]}
          >
            <Select
              showSearch
              placeholder="请选择收件人邮箱"
              optionFilterProp="children"
              style={{ textAlign: "left" }}
              onChange={handleMailbox}
              defaultValue={editingMailboxDefault?.data?.mailboxId}
            >
              {mailboxOptions}
            </Select>
          </Form.Item>
           
           
        
            <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
              <Button loading={submitLoading} type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
      
      </Modal>

    </div>
  );
};
