import { useMutation, useQuery, useQueryClient } from "react-query";
import { SystemSetting } from "../interfaces/system-setting";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";


export const useEditSystemSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SystemSetting>) => client(`systemSetting`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('systemSettingPage')
        }
    )
}
 
export const useAddSystemSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SystemSetting>) => client(`systemSetting`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('systemSettingPage')
        }
    )
}


export const useSystemSetting = () => {
    const client = useHttp()
    return useQuery<any>(
        ['systemSetting'],
        () => client(`systemSetting`,{method:'GET'})
    )
}